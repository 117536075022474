import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BsClockHistory, BsCalendar} from "react-icons/bs"
import {FaArrowAltCircleDown} from "react-icons/fa";
import Layout from "../components/Layout"
import slugify from "slugify"
import SEO from "../components/SEO"

const RecipeTemplate = ({ data }) => {
  const {
    title,
    cookTime,
    postDate,
    postUpdate,
    version,
    content,
    prepTime,
    servings,
    description: { description },
    image,
  } = data.contentfulRecipe
  const pathToImage = getImage(image)
  const {subheading1, subheading2,subheading3, tags, instructions, ingredients, tools } = content
  return (
    <Layout>
      <SEO title={title} description={description} />
      <main className="page">
        <div className="recipe-page">
          {/* hero */}
          <section className="recipe-hero">
            <GatsbyImage
              image={pathToImage}
              alt={title}
              className="blog-big-img"
            />
            <article className="recipe-info">
              <h1>{title}</h1>
              <p>{description}</p>
              {/* icons */}
              <div className="recipe-icons">
                <article>
                  <BsCalendar/>
                  <p>Post Date</p>
                  <p>{postDate}</p>
                </article>
                <article>
                  <BsClockHistory />
                  <p>Post Updated</p>
                  <p>{postUpdate}</p>
                </article>
                <article>
                  <FaArrowAltCircleDown/>
                  <p>{title} Version</p>
                  <p>{version} </p>
                </article>
              </div>
              {/* tags */}
              <p className="recipe-tags">
                Tags :
                {tags.map((tag, index) => {
                  const slug = slugify(tag, { lower: true })

                  return (
                    <Link to={`/tags/${slug}`} key={index}>
                      {tag}
                    </Link>
                  )
                })}
              </p>
            </article>
          </section>
          {/* rest of the content */}
          <section className="recipe-content">
            <article>
              {/* <h2>Why {title} for your business?</h2> */}
              <h2>{subheading1}</h2>
              {instructions.map((item, index) => {
                return (
                  <div key={index} className="single-instruction">
                    <header>
                      <p>Point {index + 1}</p>
                      <div></div>
                    </header>
                    <p>{item}</p>
                  </div>
                )
              })}
            </article>
            <article className="second-column">
              <div>
                {/* <h3>What Skills and Tools can be used?</h3> */}
                <h3>{subheading2}</h3>
                {ingredients.map((item, index) => {
                  return (
                    <p key={index} className="single-ingredient">
                      {item}
                    </p>
                  )
                })}
              </div>
              <div>
                {/* <h4>Who is supporting and using {title}?</h4> */}
                <h4>{subheading3}</h4>
                {tools.map((item, index) => {
                  return (
                    <p key={index} className="single-tool">
                      {item}
                    </p>
                  )
                })}
              </div>
            </article>
          </section>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query getSingleRecipe($title: String) {
    contentfulRecipe(title: { eq: $title }) {
      title
      cookTime
      content {
        subheading1
      subheading2
      subheading3
        ingredients
        instructions
        tags
        tools
      }
      description {
        description
      }
      postDate,
      postUpdate,
      version,
      prepTime
      servings
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }
`

export default RecipeTemplate
